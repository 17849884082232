import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        let media = gsap.matchMedia();
        
        const isHomepage = window.location.pathname === '/';  // Check if it's the homepage

        if (isHomepage) {
            media.add("(min-width: 1000px)", () => {
                this.tl = new gsap.timeline({
                    scrollTrigger: {
                        trigger: document.querySelector(".c-rail"),
                        invalidateOnRefresh: true,
                        scrub: true,
                        start: "top top",
                        end: "bottom top",
                    },
                });

                this.tl.to(this.el, { 
                    y: 0,
                    ease: "none"
                }, 0).to(this.el.querySelector(".c-menu_button.-desktop .c-menu_circle"), { 
                    scale: 0,
                    autoAlpha: 0,
                    ease: "none"
                }, 0);
            });
        } else {
            media.add("(min-width: 1000px)", () => {
                this.tl = new gsap.timeline({
                    scrollTrigger: {
                        trigger: document.querySelector(".c-inner_heading"),
                        invalidateOnRefresh: true,
                        scrub: true,
                        start: "top top",
                        end: "bottom top",
                    },
                });

                this.tl.to(this.el, { 
                    y: 0,
                    ease: "none"
                }, 0).to(this.el.querySelector(".c-menu_button.-desktop .c-menu_circle"), { 
                    scale: 0,
                    autoAlpha: 0,
                    ease: "none"
                }, 0);
            });
        }

        // Add ScrollTrigger to toggle -hide class based on scroll direction
        let lastScrollTop = 0;
        ScrollTrigger.create({
            trigger: document.body,
            start: "top top",
            onUpdate: self => {
                let st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop) {
                    // Down scroll
                    document.querySelector(".c-menu.-mobile").classList.add("-hide");
                } else {
                    // Up scroll
                    document.querySelector(".c-menu.-mobile").classList.remove("-hide");
                }
                lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
            }
        });
    }

    destroy() {
        // Clean up the GSAP timeline and its ScrollTrigger
        if (this.tl) {
            if (this.tl.scrollTrigger) {
                this.tl.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl.kill();  // Kill the timeline
            this.tl = null;  // Dereference the timeline for garbage collection
        }
        // Additional cleanup if any other resources (e.g., event listeners)
    }
}
