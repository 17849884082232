import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        // Add the second animation sequence with to, chaining it after the first animation
        this.tl = new gsap.timeline({
            scrollTrigger: {
                trigger: ".c-outro",
                invalidateOnRefresh: true,
                scrub: true,
                start: "top bottom",
                end: "bottom top", // scene duration
            },
            force3d: true,
        });

        this.tl.fromTo(".c-outro .-work", {
            yPercent: 50,
        }, {
            yPercent: -5,
            ease: "none",
        }, 0).fromTo(".c-outro .-faq", {
            yPercent: -45,
        }, {
            yPercent: 25,
            ease: "none",
        }, 0).fromTo(".c-outro .-inside", {
            yPercent: 100,
        }, {
            yPercent: -40,
            ease: "none",
        }, 0);
    }

    destroy() {
        // Clean up the GSAP timeline and its ScrollTrigger
        if (this.tl) {
            if (this.tl.scrollTrigger) {
                this.tl.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl.kill();  // Kill the timeline
            this.tl = null;  // Dereference the timeline for garbage collection
        }
        // Additional cleanup if any other resources (e.g., event listeners)
    }    
}
