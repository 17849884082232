import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
       gsap.registerPlugin(ScrollTrigger);

       let media = gsap.matchMedia();
       
       media.add("(min-width: 1000px)", () => {
            let requestActive = false;
            let reviewActive = false;
            let repeatActive = false;

            // this.requestTimelineStart = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: ".c-approach_steps",
            //         endTrigger: ".c-discover .c-heading.-h3",
            //         invalidateOnRefresh: true,
            //         pin: true,
            //         pinSpacing: false,
            //         anticipatePin: 1,
            //         scrub: true,
            //         start: "center center",
            //         end: "bottom bottom",
            //     },
            // })

            // this.requestTimelineStart = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: ".c-marquee-container",
            //         invalidateOnRefresh: true,
            //         scrub: true,
            //         start: "top bottom",
            //         end: "top top",
            //         onEnter: () => {
            //             document.querySelector('.c-marquee.-request').classList.add('-active');
            //         },
            //         onLeaveBack: () => {
            //             requestActive = true;
            //             document.querySelector('.c-marquee.-request').classList.remove('-active');
            //             document.querySelector('.c-marquee.-review').classList.remove('-active');
            //             document.querySelector('.c-marquee.-repeat').classList.remove('-active');
            //         }
            //     },
            // }).fromTo(".c-marquee-container", {
            //     autoAlpha: 0,
            // }, {
            //     autoAlpha: 1,
            //     ease: "none"
            // }, 0)

            this.requestTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: ".c-approach_steps",
                    endTrigger: ".c-approach_chips-request",
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "center center",
                    end: "bottom bottom",
                    onEnter: () => {
                        requestActive = true;
                        reviewActive = false;
                        repeatActive = false;
                        document.querySelector('.c-marquee.-request').classList.add('-active');
                        setTimeout(() => {
                            if (requestActive == true) {
                                document.querySelector('.c-marquee.-review').classList.remove('-active');
                                document.querySelector('.c-marquee.-repeat').classList.remove('-active');
                            }
                        }, 1000);

                        document.querySelector('.c-marquee-container').classList.add('-step-1');

                        document.querySelector('.c-marquee-container').classList.remove('-step-2');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-2');

                        document.querySelector('.c-marquee-container').classList.remove('-step-3');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-3');
                    },
                    onEnterBack: () => {
                        requestActive = true;
                        reviewActive = false;
                        repeatActive = false;
                        document.querySelector('.c-marquee.-request').classList.add('-active');
                        setTimeout(() => {
                            if (requestActive == true) {
                                document.querySelector('.c-marquee.-review').classList.remove('-active');
                                document.querySelector('.c-marquee.-repeat').classList.remove('-active');
                            }
                        }, 1000);

                        document.querySelector('.c-marquee-container').classList.add('-step-1');

                        document.querySelector('.c-marquee-container').classList.remove('-step-2');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-2');

                        document.querySelector('.c-marquee-container').classList.remove('-step-3');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-3');
                    }
                },
                force3D: true,
            }).to(".c-approach_chips-request .c-approach_chips-chip.-orange",
            {
                y: "70vh",
                scale: 1.1,
                ease: "none"
            }, 0).to(".c-approach_chips-request .c-approach_chips-chip.-purple",
            {
                y: "85vh",
                scale: 1.2,
                ease: "none"
            }, 0).to(".c-approach_chips-request .c-approach_chips-gradients",
            {
                y: "75vh",
                ease: "none"
            }, 0)

            this.reviewTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: ".c-approach_chips-review",
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom bottom",
                    onEnter: () => {
                        requestActive = false;
                        reviewActive = true;
                        repeatActive = false;
                        document.querySelector('.c-marquee.-review').classList.add('-active');
                        setTimeout(() => {
                            if (reviewActive == true) {
                                document.querySelector('.c-marquee.-request').classList.remove('-active');
                                document.querySelector('.c-marquee.-repeat').classList.remove('-active');
                            }
                        }, 1000)

                        document.querySelector('.c-marquee-container').classList.add('-step-2');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.add('-step-2');

                        document.querySelector('.c-marquee-container').classList.remove('-step-1');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-1');

                        document.querySelector('.c-marquee-container').classList.remove('-step-3');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-3');
                    },
                    onEnterBack: () => {
                        requestActive = false;
                        reviewActive = true;
                        repeatActive = false;
                        document.querySelector('.c-marquee.-review').classList.add('-active');
                        setTimeout(() => {
                            if (reviewActive == true) {
                                document.querySelector('.c-marquee.-request').classList.remove('-active');
                                document.querySelector('.c-marquee.-repeat').classList.remove('-active');
                            }
                        }, 1000)

                        document.querySelector('.c-marquee-container').classList.add('-step-2');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.add('-step-2');

                        document.querySelector('.c-marquee-container').classList.remove('-step-1');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-1');

                        document.querySelector('.c-marquee-container').classList.remove('-step-3');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-3');
                    }
                },
                force3D: true,
            }).to(".c-approach_chips-review .c-approach_chips-chip.-orange",
            {
                y: "85vh",
                scale: 1.2,
                ease: "none"
            }, 0).to(".c-approach_chips-review .c-approach_chips-chip.-purple",
            {
                y: "70vh",
                scale: 1.1,
                ease: "none"
            }, 0).to(".c-approach_chips-review .c-approach_chips-gradients",
            {
                y: "75vh",
                ease: "none"
            }, 0)

            this.repeatTimeline = gsap.timeline({
                scrollTrigger: {
                    trigger: ".c-approach_chips-repeat",
                    endTrigger: ".c-approach",
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom bottom",
                    onEnter: () => {
                        requestActive = false;
                        reviewActive = false;
                        repeatActive = true;
                        document.querySelector('.c-marquee.-repeat').classList.add('-active');
                        setTimeout(() => {
                            if (repeatActive == true) {
                                document.querySelector('.c-marquee.-request').classList.remove('-active');
                            }
                            if (repeatActive == true) {
                                document.querySelector('.c-marquee.-review').classList.remove('-active');
                            }
                        }, 1000);

                        document.querySelector('.c-marquee-container').classList.add('-step-3');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.add('-step-3');

                        document.querySelector('.c-marquee-container').classList.remove('-step-1');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-1');

                        document.querySelector('.c-marquee-container').classList.remove('-step-2');
                        document.querySelector('.c-approach .medium-show .c-approach_description').classList.remove('-step-2');
                    },
                },
                force3D: true,
            }).to(".c-approach_chips-repeat .c-approach_chips-chip.-orange",
            {
                y: "35vh",
                scale: 1.05,
                ease: "none"
            }, 0).to(".c-approach_chips-repeat .c-approach_chips-chip.-purple",
            {
                y: "42.5vh",
                scale: 1.1,
                ease: "none"
            }, 0).to(".c-approach_chips-repeat .c-approach_chips-gradients",
            {
                y: "37.5vh",
                ease: "none"
            }, 0)

            this.repeatTimelineEnd = gsap.timeline({
                scrollTrigger: {
                    trigger: ".c-approach",
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "bottom bottom",
                    end: "bottom top",
                    onLeave: () => {
                        document.querySelector('.c-marquee.-repeat').classList.remove('-active');
                    },
                    onEnterBack: () => {
                        document.querySelector('.c-marquee.-repeat').classList.add('-active');
                    },
                },
            })
        });

        
        // if (!this.isMobileDevice()) {
        //     // Debounce function
        //     const debounce = (func, wait) => {
        //         let timeout;
        //         return function executedFunction() {
        //             const later = () => {
        //                 clearTimeout(timeout);
        //                 func();
        //             };
        //             clearTimeout(timeout);
        //             timeout = setTimeout(later, wait);
        //         };
        //     };

        //     // Debounced refresh for ScrollTrigger
        //     const debouncedRefresh = debounce(() => {
        //         ScrollTrigger.refresh();
        //     }, 250);

        //     // Event listener for resizing the window
        //     window.addEventListener('resize', debouncedRefresh);
        // }
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    };

    // Make sure to properly clean up the observer when the module is destroyed
    destroy() {
        // Properly clean up the ScrollTriggers to prevent memory leaks
        if (this.requestTimelineStart) {
            this.requestTimelineStart.scrollTrigger.kill();
            this.requestTimelineStart.kill();
            this.requestTimelineStart = null;
        }
        
        if (this.requestTimeline) {
            this.requestTimeline.scrollTrigger.kill();
            this.requestTimeline.kill();
            this.requestTimeline = null;
        }

        if (this.reviewTimeline) {
            this.reviewTimeline.scrollTrigger.kill();
            this.reviewTimeline.kill();
            this.reviewTimeline = null;
        }

        if (this.repeatTimeline) {
            this.repeatTimeline.scrollTrigger.kill();
            this.repeatTimeline.kill();
            this.repeatTimeline = null;
        }

        if (this.repeatTimelineEnd) {
            this.repeatTimelineEnd.scrollTrigger.kill();
            this.repeatTimelineEnd.kill();
            this.repeatTimelineEnd = null;
        }
    }
    
}
