import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        this.isInView();
       
        let media = gsap.matchMedia();
       
        media.add("(min-width: 1000px)", () => {
            if (!this.isMobileDevice()) {
                this.tl = new gsap.timeline({
                    scrollTrigger: {
                        trigger: document.querySelector(".c-capabilities"),
                        invalidateOnRefresh: true,
                        scrub: true,
                        start: "top bottom",
                        end: "bottom top",
                    },
                });

                this.tl.fromTo(".c-capabilities_image .c-image", {
                    xPercent: 8,
                    yPercent: -4,
                }, { 
                    xPercent: 0,
                    yPercent: 1.5,
                    ease: "none"
                }, 0);
            }
        });
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    }

    isInView() {
        this.inView = new gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                invalidateOnRefresh: true,
                start: "-=300px bottom",
                end: "bottom -=300px",
                scrub: true,
                onEnter: () => {
                    this.el.classList.add('is-inview');
                },
                onLeave: () => {
                    this.el.classList.remove('is-inview');
                },
                onEnterBack: () => {
                    this.el.classList.add('is-inview');
                },
                onLeaveBack: () => {
                    this.el.classList.remove('is-inview');
                },                
            }
        });
    }

    destroy() {
        if (this.inView) {
            if (this.inView.scrollTrigger) {
                this.inView.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.inView.kill();  // Kill the timeline
            this.inView = null;  // Dereference the timeline for garbage collection
        }
    }
}
