import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';
import modularLoad from 'modularload';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);
        
        const load = new modularLoad({
            enterDelay: 500,
            transitions: {
                customTransition: {}
            }
        });

        load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            window.scrollTo(0, 0);
            this.call('update', newContainer, 'app');
            setTimeout(() => {
                ScrollTrigger.refresh();
            }, 400);
        });
    }
}
