
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        let media = gsap.matchMedia();
       
        media.add("(min-width: 1600px)", () => {
            this.tl = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-hero"),
                    endTrigger: document.querySelector(".c-introduction .c-heading"),
                    invalidateOnRefresh: true,
                    scrub: true,
                    pin: ".c-hero_sides-right",
                    pinSpacing: false,
                    start: "top top",
                    end: "top bottom",
                },
            });

            this.tl.to(".c-hero_sides-left", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-right p", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-right .c-wave", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-left p", { 
                yPercent: -150,
                ease: "none"
            }, 0);


            this.tl2 = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-hero"),
                    endTrigger: document.querySelector(".c-introduction .c-heading"),
                    invalidateOnRefresh: true,
                    scrub: true,
                    pin: ".c-hero_sides-left svg",
                    pinSpacing: false,
                    start: "top top",
                    end: "top bottom",
                },
            });
        });

        media.add("(min-width: 1200px) and (max-width: 1599px)", () => {
            this.tl = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-hero"),
                    endTrigger: document.querySelector(".c-introduction .c-heading"),
                    invalidateOnRefresh: true,
                    scrub: true,
                    pin: ".c-hero_sides-right",
                    pinSpacing: false,
                    start: "top top",
                    end: "top bottom",
                },
            });

            this.tl.to(".c-hero_sides-left", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-right .c-wave", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-right p", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-left p", { 
                yPercent: -110,
                ease: "none"
            }, 0).to(".c-hero_sides-left svg", { 
                yPercent: -130,
                ease: "none"
            }, 0);
        });

        media.add("(min-width: 1000px) and (max-width: 1199px)", () => {
            this.tl = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-hero"),
                    endTrigger: document.querySelector(".c-introduction .c-heading"),
                    invalidateOnRefresh: true,
                    scrub: true,
                    pin: ".c-hero_sides-right",
                    pinSpacing: false,
                    start: "top top",
                    end: "top bottom",
                },
            });

            this.tl.to(".c-hero_sides-left", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-right .c-wave", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-right p", { 
                autoAlpha: 0,
                ease: "none"
            }, 0).to(".c-hero_sides-left p", { 
                yPercent: -85,
                ease: "none"
            }, 0).to(".c-hero_sides-left svg", { 
                yPercent: -145,
                ease: "none"
            }, 0);
        });
    }

    destroy() {
        // Clean up the GSAP timeline and its ScrollTrigger
        if (this.tl) {
            if (this.tl.scrollTrigger) {
                this.tl.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl.kill();  // Kill the timeline
            this.tl = null;  // Dereference the timeline for garbage collection
        }

        if (this.tl2) {
            if (this.tl2.scrollTrigger) {
                this.tl2.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl2.kill();  // Kill the timeline
            this.tl2 = null;  // Dereference the timeline for garbage collection
        }
        // Additional cleanup if any other resources (e.g., event listeners)
    }
}
