import gsap from "gsap";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        const media = gsap.matchMedia();

        media.add("(min-width: 1000px)", () => {
            this.svgElement = this.el; // Assuming the SVG is directly referenced
            this.setupAnimations();
            this.isInView();
        });
    }

    setupAnimations() {
        const path = this.svgElement.querySelector('path');
        const width = this.svgElement.clientWidth;
        const height = this.svgElement.clientHeight;

        this.lastTime = 0;
        const updatePath = (time) => {
            if (!this.svgElement.classList.contains('is-inview')) {
                this.rafId = requestAnimationFrame(updatePath); // Continue checking
                return;
            }

            if (!this.lastTime) this.lastTime = time; // Initialize lastTime on the first frame
            const deltaTime = time - this.lastTime; // Calculate time difference for smooth animation
            this.lastTime = time;

            let d = `M ${width / 2} 0 `;
            for (let y = 0; y <= height; y++) {
                const amplitude = Math.min(10 - y / 10, 3);
                const phase = (time * 0.005) + (y * 0.3);
                const xDisplacement = amplitude * Math.sin(phase);
                d += `L ${width / 2 + xDisplacement} ${y} `;
            }
            d += `M ${width / 2 - 9} ${height - 9} L ${width / 2} ${height} L ${width / 2 + 9} ${height - 9}`;
            path.setAttribute('d', d);

            this.rafId = requestAnimationFrame(updatePath); // Continue the loop
        };

        this.rafId = requestAnimationFrame(updatePath); // Start the animation loop
    }

    isInView() {
        this.inView = new gsap.timeline({
            scrollTrigger: {
                trigger: document.querySelector(".c-hero"),
                invalidateOnRefresh: true,
                start: "-=300px bottom",
                end: "bottom -=300px",
                scrub: true,
                onEnter: () => {
                    this.el.classList.add('is-inview');
                },
                onLeave: () => {
                    this.el.classList.remove('is-inview');
                },
                onEnterBack: () => {
                    this.el.classList.add('is-inview');
                },
                onLeaveBack: () => {
                    this.el.classList.remove('is-inview');
                },
            }
        });
    }

    destroy() {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId); // Properly stop the animation frame when destroying the module
            this.rafId = null;
        }
    }
    
}
