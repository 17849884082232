import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);
        this.isInView();
    }

    isInView() {
        this.inView = new gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                invalidateOnRefresh: true,
                start: "-=300px bottom",
                end: "bottom -=300px",
                scrub: true,
                onEnter: () => this.addInViewClass(),
                onLeave: () => this.removeInViewClassWithDelay(),
                onEnterBack: () => this.addInViewClassWithDelay(),
            }
        });
    }

    addInViewClass() {
        if (!this.el.classList.contains('is-inview')) {
            this.el.classList.add('is-inview');
        }
    }

    removeInViewClassWithDelay() {
        this.el.classList.remove('is-inview');
        this.removeInViewTimeout = setTimeout(() => {
            this.el.classList.remove('is-inview');
            if (this.el && !this.el.classList.contains('is-inview')) {
                this.el.classList.remove('is-inview');
            }
        }, 1000);
    }

    addInViewClassWithDelay() {
        clearTimeout(this.removeInViewTimeout); // Clear any pending removal timeout
        this.addInViewTimeout = setTimeout(() => {
            if (this.el && !this.el.classList.contains('is-inview')) {
                this.el.classList.add('is-inview');
            }
        }, 1000);
    }

    destroy() {
        // Clean up the GSAP timeline and its ScrollTrigger
        if (this.inView) {
            if (this.inView.scrollTrigger) {
                this.inView.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.inView.kill();  // Kill the timeline
            this.inView = null;  // Dereference the timeline for garbage collection
        }
        // Clear timeouts to prevent memory leaks
        clearTimeout(this.removeInViewTimeout);
        clearTimeout(this.addInViewTimeout);

        // Additional cleanup if any other resources (e.g., event listeners)
    }
}
