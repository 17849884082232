import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        // Define an array of month names
        const months = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];

        // Get the current month as a number (0-11)
        const currentMonthIndex = new Date().getMonth();

        // Use the month number to retrieve the month name
        this.currentMonthName = months[currentMonthIndex];

        // Update the element's text content to show the current month name
        this.el.textContent = this.currentMonthName;
    }

    destroy() {
        // Safely clear the content of all matched elements if any exist
        if (this.el) {
            this.el.textContent = '';
        }
    }
}
