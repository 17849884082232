import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.renderBind = this.render.bind(this);
        this.checkResizeBind = this.checkResize.bind(this); 
        this.startDrag = this.startDrag.bind(this);
        this.onDrag = this.onDrag.bind(this);
        this.endDrag = this.endDrag.bind(this);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);
        
        const media = gsap.matchMedia();

        ScrollTrigger.config({ ignoreMobileResize: true });

        this.loadDataFlags();
        this.originalHTML = this.el.innerHTML;
        
        this.setupMediaQueries();
        this.compute(); 

        window.addEventListener("resize", this.checkResizeBind);
        
        this.isInView();
        
        if (this.draggable) {
            this.setupDrag();
        }
    }

    setupMediaQueries() {
        const media = gsap.matchMedia();
        media.add("(min-width: 1800px)", () => {
            if (this.reversed) {
                this.marqueeSpeed = -3;
            } else {
                this.marqueeSpeed = 3;
            }
        });
        media.add("(min-width: 1000px) and (max-width: 1799px)", () => { 
            if (this.reversed) {
                this.marqueeSpeed = -2.5;
            } else {
                this.marqueeSpeed = 2.5;
            }
        });
        media.add("(max-width: 999px)", () => {
            if (this.reversed) {
                this.marqueeSpeed = -2;
            } else {
                this.marqueeSpeed = 2;
            }
        });
    }

    loadDataFlags() {
        this.mobile = this.getData("mobile") != null;
        this.reversed = this.getData("reversed") != null;
        this.draggable = this.getData("draggable") != null;
    }

    checkResize() {
        if (!this.resizeTick) {
            this.resizeTick = true;
            requestAnimationFrame(() => {
                this.resizeTick = false;
                if (this.viewportWidth !== window.innerWidth) {
                    this.compute();
                }
            });
        }
    }

    isInView() {
        this.inView = new gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                invalidateOnRefresh: true,
                start: "-=300px bottom",
                end: "bottom -=300px",
                scrub: true,
                onEnter: () => {
                    this.el.classList.add('is-inview');
                    gsap.ticker.add(this.renderBind);
                },
                onLeave: () => {
                    this.el.classList.remove('is-inview');
                    gsap.ticker.remove(this.renderBind);
                },
                onEnterBack: () => {
                    this.el.classList.add('is-inview');
                    gsap.ticker.add(this.renderBind);
                },
                onLeaveBack: () => {
                    this.el.classList.remove('is-inview');
                    gsap.ticker.remove(this.renderBind);
                },                
            }
        });
    }

    compute() {
        this.el.innerHTML = this.originalHTML;
        this.viewportWidth = window.innerWidth;
        this.contentWidth = this.el.querySelector('.c-marquee_outer').getBoundingClientRect().width;
        this.currentOffset = 0;
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    }

    render() {
        const delta = gsap.ticker.deltaRatio();
        this.currentOffset += this.marqueeSpeed * delta;

        if ((this.marqueeSpeed > 0 && this.currentOffset >= this.contentWidth) ||
            (this.marqueeSpeed < 0 && this.currentOffset <= -this.contentWidth)) {
            this.currentOffset -= Math.sign(this.marqueeSpeed) * this.contentWidth;
        }

        gsap.set(this.el.querySelectorAll('.c-marquee_outer'), {
            x: this.currentOffset,
            force3D: true
        });
    }

    start() {
        gsap.ticker.add(this.renderBind);
    }

    setupDrag() {
        this.el.addEventListener('mousedown', this.startDrag);
        this.el.addEventListener('mousemove', this.onDrag);
        this.el.addEventListener('touchstart', this.startDrag, { passive: false });
        this.el.addEventListener('touchmove', this.onDrag, { passive: false });
    }

    startDrag(e) {
        if (e.button === 2) return;  // Ignore right-clicks for starting drag
    
        if (this.marqueeTween) {
            this.marqueeTween.kill();
        }
    
        this.isDragging = true;
        const touch = e.type.includes('touch') ? e.touches[0] : e;
        this.startX = touch.pageX;
        this.startY = touch.pageY;
    
        this.lastDragDirection = 0; // Reset last drag direction at start
    
        window.addEventListener('mouseup', this.endDrag);
        window.addEventListener('touchend', this.endDrag);
        window.addEventListener('mouseout', function(event) {
            if (event.toElement === null && event.relatedTarget === null) {
                this.endDrag(); // Ends drag if the mouse leaves the browser window
            }
        }.bind(this));
    }
    
    onDrag(e) {
        if (!this.isDragging) return;
    
        const touch = e.type.includes('touch') ? e.touches[0] : e;
        const currentX = touch.pageX;
        const currentY = touch.pageY;
        const dragDistanceX = currentX - this.startX;
        const dragDistanceY = currentY - this.startY;
    
        // Determine the absolute distances dragged in each direction
        const absDragDistanceX = Math.abs(dragDistanceX);
        const absDragDistanceY = Math.abs(dragDistanceY);
    
        // Prioritize vertical movements for normal scrolling only on mobile devices
        if (this.isMobileDevice() && absDragDistanceY > absDragDistanceX) {
            if (this.isDragging) {
                this.endDrag();
            }
            return; // Allow default browser behavior (scrolling)
        }
    
        // Prevent default behavior if horizontal dragging is detected
        if (e.cancelable) {
            e.preventDefault();
        }
    
        // Continue with dragging logic if predominantly horizontal movement
        if (this.isDragging) {
            let tempSpeed = absDragDistanceX / 1 * Math.sign(dragDistanceX);
            this.marqueeSpeed = Math.min(Math.abs(tempSpeed), 100) * Math.sign(tempSpeed);
    
            if (Math.sign(dragDistanceX) !== 0) {
                this.lastDragDirection = Math.sign(dragDistanceX); // Update last drag direction
            }
    
            // Update start position for continuous tracking
            this.startX = currentX;
        }
    }
    
    
    endDrag() {
        if (!this.isDragging) return;
        this.isDragging = false;
    
        // Use lastDragDirection to determine the direction if none was set during dragging
        if (this.lastDragDirection === 0) {
            this.lastDragDirection = this.getDefaultDirection();
        }
    
        // Calculate target speed based on last direction and default speed
        const targetSpeed = this.lastDragDirection * Math.abs(this.getDefaultSpeed());
        this.marqueeTween = gsap.to(this, {
            marqueeSpeed: targetSpeed,
            duration: 1.5,
            ease: "expo.out",
        });
    
        window.removeEventListener('mouseup', this.endDrag);
        window.removeEventListener('touchend', this.endDrag);
        window.removeEventListener('mouseout', this.endDrag);
    }

    getDefaultSpeed() {
        if (window.innerWidth >= 1800) {
            return 3;
        } else if (window.innerWidth >= 1000 && window.innerWidth < 1800) {
            return 2.25;
        } else {
            return 1.75;
        }
    }

    getDefaultDirection() {
        return this.reversed ? -1 : 1;  // 1 for right, -1 for left
    }

    destroy() {
        gsap.ticker.remove(this.renderBind);
        window.removeEventListener("resize", this.checkResizeBind);

        if (this.inView) {
            this.inView.scrollTrigger.kill();
            this.inView.kill();
            this.inView = null;
        }

        if (this.marqueeTween) {
            this.marqueeTween.kill();
            this.marqueeTween = null;
        }

        this.el.removeEventListener('mousedown', this.startDrag);
        this.el.removeEventListener('touchstart', this.startDrag);
        this.el.removeEventListener('mousemove', this.onDrag);
        this.el.removeEventListener('touchmove', this.onDrag);
        window.removeEventListener('mouseup', this.endDrag);
        window.removeEventListener('touchend', this.endDrag);
        window.removeEventListener('mouseout', this.endDrag);
    }
}
