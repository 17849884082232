import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

// Ensure GSAP plugins are only registered once.
gsap.registerPlugin(ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);
        this.split = null;
        this.tl = null;
        this.lastToggleTime = 0;
        this.toggleDelay = 400;
        this.descriptionElement = null; // Caching the DOM element to avoid repeated queries.

        // Bind methods to ensure the same reference is used for adding and removing event listeners
        this.debounceToggle = this.debounceToggle.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
    }

    init() {
        this.descriptionElement = this.el.querySelector(".c-faq_item-description p");
        this.el.addEventListener('click', this.debounceToggle);
    }

    debounceToggle() {
        const currentTime = Date.now();
        if (currentTime - this.lastToggleTime > this.toggleDelay) {
            this.handleToggle();
            this.lastToggleTime = currentTime;
        }
    }

    handleToggle() {
        this.el.classList.toggle("-active");

        if (window.innerWidth >= 1000) {
            setTimeout(() => ScrollTrigger.refresh(), 400);
        }
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.includes("IEMobile");
    }

    destroy() {
        this.el.removeEventListener("click", this.debounceToggle);
        if (this.split) {
            this.split.revert();
            this.split = null;
        }
        if (this.tl) {
            this.tl.kill();
            this.tl = null;
        }
        this.descriptionElement = null; // Ensure the cached element is also cleaned up.
    }
}
