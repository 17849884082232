import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.el.addEventListener('click', this.handleClick.bind(this));
    }

    handleClick() {
        if (navigator.clipboard) {
            // Modern approach with Clipboard API
            navigator.clipboard.writeText('WELCOME15').then(() => {
                this.addClassToElement();
            }).catch(err => {
                console.error('Could not copy text: ', err);
                this.fallbackCopyText();
            });
        } else {
            // Fallback method for older browsers
            this.fallbackCopyText();
        }
    }

    fallbackCopyText() {
        // Create a temporary text field
        const tempInput = document.createElement('textarea'); // using textarea to handle multiline text well
        tempInput.style.position = 'absolute';
        tempInput.style.opacity = '0'; // less intrusive, prevents keyboard from popping up
        tempInput.setAttribute('readonly', ''); // prevents keyboard on focus in mobile browsers
        document.body.appendChild(tempInput);
        tempInput.value = 'WELCOME15';
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                this.addClassToElement();
            } else {
                console.log('Fallback: Copy was unsuccessful');
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        // Clean up
        document.body.removeChild(tempInput);
    }

    addClassToElement() {
        const couponElement = document.querySelector('.c-gift_coupon');
        if (couponElement) {
            couponElement.classList.add('is-copied');
        } else {
            console.error('Element .c-gift_coupon not found');
        }
    }

    destroy() {
        this.el.removeEventListener('click', this.handleClick.bind(this));
    }    
}
