import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        let media = gsap.matchMedia();

        media.add("(min-width: 1000px)", () => {        
            this.textAnim = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-introduction"),
                    invalidateOnRefresh: true,
                    overwrite: true,
                    scrub: true,
                    start: "bottom bottom",
                    end: "bottom -=25%",
                },
            });

            this.textAnim.fromTo(".c-approach_steps", {
                autoAlpha: 0,
            }, { 
                autoAlpha: 1,
                ease: "none"
            }, 0);

            this.titleExit = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-discover"),
                    invalidateOnRefresh: true,
                    overwrite: true,
                    scrub: true,
                    start: "top bottom",
                    end: "top center",
                },
            });

            this.titleExit.fromTo(".c-approach_title h3", {
                autoAlpha: 1,
            }, { 
                autoAlpha: 0,
                ease: "none"
            }, 0).fromTo(".c-approach_title i", {
                autoAlpha: 1,
            }, { 
                autoAlpha: 0,
                ease: "none"
            }, 0).fromTo(".c-approach_sticky .o-container", {
                autoAlpha: 1,
            }, { 
                autoAlpha: 0,
                ease: "none"
            }, 0);

            this.requestEnter = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-approach_chips-step.-request"),
                    invalidateOnRefresh: true,
                    overwrite: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom top",
                },
                force3d: true,
            });

            this.requestEnter.fromTo(".c-approach_chips-step.-request .-left .c-load", {
                yPercent: 5,
            }, { 
                yPercent: -25,
                ease: "none"
            }, 0).to(".c-approach_gradients.-request", {
                y: "-100vh",
                ease: "none"
            }, 0).fromTo(".c-approach_gradients.-request img", {
                autoAlpha: 0,
            }, { 
                autoAlpha: 1,
                duration: 0.1,
                ease: "none"
            }, 0);

            this.reviewEnter = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-approach_chips-step.-review"),
                    invalidateOnRefresh: true,
                    overwrite: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom top",
                    onEnter: () => {
                        document.querySelector(".c-approach_steps").classList.add('-review');
                    },
                    onLeaveBack: () => {
                        document.querySelector(".c-approach_steps").classList.remove('-review');
                    },
                },
                force3d: true,
            });

            this.reviewEnter.to(".c-approach_gradients.-review", {
                y: "-100vh",
                ease: "none"
            }, 0).fromTo(".c-approach_chips-step.-review .-right .c-load", {
                yPercent: 20,
            }, { 
                yPercent: -20,
                ease: "none"
            }, 0).fromTo(".c-approach_gradients.-review img", {
                autoAlpha: 0,
            }, { 
                autoAlpha: 1,
                duration: 0.1,
                ease: "none"
            }, 0);
    
            this.repeatEnter = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-approach_chips-step.-repeat"),
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom top",
                    onEnter: () => {
                        document.querySelector(".c-approach_steps").classList.remove('-review');
                        document.querySelector(".c-approach_steps").classList.add('-repeat');
                    },
                    onLeaveBack: () => {
                        document.querySelector(".c-approach_steps").classList.remove('-repeat');
                        document.querySelector(".c-approach_steps").classList.add('-review');
                    },
                },
                force3d: true,
            });

            this.repeatEnter.fromTo(".c-approach_chips-step.-repeat .-right .c-load", {
                yPercent: 0,
            }, { 
                yPercent: -15,
                ease: "none"
            }, 0).fromTo(".c-approach_chips-step.-repeat .-left .c-load", {
                yPercent: 20,
            }, { 
                yPercent: -30,
                ease: "none"
            }, 0).to(".c-approach_gradients.-repeat", {
                y: "-100vh",
                ease: "none"
            }, 0).fromTo(".c-approach_gradients.-repeat img", {
                autoAlpha: 0,
            }, { 
                autoAlpha: 1,
                duration: 0.1,
                ease: "none"
            }, 0);
        });
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    }

    destroy() {
        // Clean up the GSAP timeline and its ScrollTrigger
        if (this.tl) {
            if (this.tl.scrollTrigger) {
                this.tl.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl.kill();  // Kill the timeline
            this.tl = null;  // Dereference the timeline for garbage collection
        }

        if (this.titleExit) {
            if (this.titleExit.scrollTrigger) {
                this.titleExit.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.titleExit.kill();  // Kill the timeline
            this.titleExit = null;  // Dereference the timeline for garbage collection
        }

        if (this.textAnim) {
            if (this.textAnim.scrollTrigger) {
                this.textAnim.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.textAnim.kill();  // Kill the timeline
            this.textAnim = null;  // Dereference the timeline for garbage collection
        }

        if (this.reviewEnter) {
            if (this.reviewEnter.scrollTrigger) {
                this.reviewEnter.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.reviewEnter.kill();  // Kill the timeline
            this.reviewEnter = null;  // Dereference the timeline for garbage collection
        }

        if (this.repeatEnter) {
            if (this.repeatEnter.scrollTrigger) {
                this.repeatEnter.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.repeatEnter.kill();  // Kill the timeline
            this.repeatEnter = null;  // Dereference the timeline for garbage collection
        }
    }
}
