import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.navElement = document.querySelector('.c-navigation');
        this.overlayElement = document.querySelector('.c-overlay');
        this.closeButtons = document.querySelectorAll('.c-navigation_close');
        this.openButtons = document.querySelectorAll('.c-menu_button');
        this.openButton = document.querySelector('.c-menu_button.-desktop');
        this.navAnchors = document.querySelectorAll('.c-navigation .c-navigation_anchor');
    }

    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.closeButtons.forEach(button => button.addEventListener('click', this.closeNavigation.bind(this)));
        this.openButtons.forEach(button => button.addEventListener('click', this.openNavigation.bind(this)));
        this.navAnchors.forEach(anchor => anchor.addEventListener('click', this.closeNavigation.bind(this)));
        this.overlayElement.addEventListener('click', this.closeNavigation.bind(this));
    }

    openNavigation() {
        this.navElement.classList.add("active");
        this.overlayElement.classList.add("active");
        this.openButton.classList.add("active");
        // this.overlayElement.setAttribute('data-cursor-icon', 'closing');
    }

    closeNavigation() {
        this.navElement.classList.remove("active");
        this.overlayElement.classList.remove("active");
        this.openButton.classList.remove("active");
    }

    destroy() {
        // Remove event listeners for close buttons
        this.closeButtons.forEach(button => button.removeEventListener('click', this.closeNavigation.bind(this)));
    
        // Remove event listeners for open buttons
        this.openButtons.forEach(button => button.removeEventListener('click', this.openNavigation.bind(this)));
    
        // Remove event listeners for navigation anchors
        this.navAnchors.forEach(anchor => anchor.removeEventListener('click', this.closeNavigation.bind(this)));
    
        // Remove event listener from the overlay element
        this.overlayElement.removeEventListener('click', this.closeNavigation.bind(this));
    }    
}