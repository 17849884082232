import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.modalElement = document.querySelector('.c-modal');
        this.closeButtons = document.querySelectorAll('.c-modal_close');
        this.openButtons = document.querySelectorAll('.c-modal_open');
        this.giftElement = document.querySelector('.c-gift');
        this.couponElement = document.querySelector('.c-gift_coupon');
        this.isTransitioning = false; // State flag to track transitions
    }

    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.closeButtons.forEach(button => button.addEventListener('click', this.closeModal.bind(this)));
        this.openButtons.forEach(button => button.addEventListener('click', this.openModal.bind(this)));
    }

    openModal() {
        if (!this.isTransitioning) {
            this.isTransitioning = true;
            this.modalElement.classList.add("is-active");
            this.giftElement.classList.add("is-active");
            this.isTransitioning = false;
        }
    }

    closeModal() {
        if (!this.isTransitioning) {
            this.isTransitioning = true;
            this.modalElement.classList.remove("is-active");
            setTimeout(() => {
                this.couponElement.classList.remove("is-copied");
            }, 200);
            if (!this.modalElement.classList.contains("is-active")) {
                setTimeout(() => {
                    this.giftElement.classList.remove("is-active");
                    this.isTransitioning = false; // Reset flag after transition
                }, 400);
            } else {
                this.isTransitioning = false; // Reset flag if no timeout is needed
            }

            const cursorElement = document.querySelector('.c-cursor');
            if (cursorElement) {
                cursorElement.classList.remove("-text");
            }
        }
    }

    destroy() {
        this.closeButtons.forEach(button => button.removeEventListener('click', this.closeModal));
        this.openButtons.forEach(button => button.removeEventListener('click', this.openModal));
    }    
}
