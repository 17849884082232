import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.lastToggleTime = 0; // Initialize the last toggle time
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        let media = gsap.matchMedia();

        media.add("(min-width: 1000px)", () => {
            this.tl = new gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-plans_headings"),
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "top bottom",
                    end: "center center",
                },
                force3d: true,
            });

            this.tl.fromTo(".c-plans_headings", {
                autoAlpha: 0.2,
            }, { 
                autoAlpha: 1,
                ease: "none"
            }, 0);
        });

        gsap.defaults({ overwrite: true });

        document.querySelector(".c-plans_video.-pro").playsInline = true;
        document.querySelector(".c-plans_video.-core").playsInline = true;

        document.querySelector(".c-plans_video.-pro").preload = "auto";
        document.querySelector(".c-plans_video.-core").preload = "auto";

                
        if (!this.isMobileDevice()) {
            document.querySelectorAll(".c-plans_headings").forEach(switchElement => {
                switchElement.addEventListener('click', this.toggleProClass.bind(this));
            });

            document.querySelectorAll(".c-plans_switch").forEach(switchElement => {
                switchElement.addEventListener('click', this.toggleProClass.bind(this));
            });

            document.querySelectorAll(".c-plans_switch-desktop").forEach(switchElement => {
                switchElement.addEventListener('click', this.toggleProClass.bind(this));
            });
        }
        
        if (this.isMobileDevice()) {
            document.querySelectorAll(".c-plans_videos").forEach(switchElement => {
                switchElement.addEventListener('click', this.toggleProClass.bind(this));
            });
            
            document.querySelectorAll(".c-plans_switch").forEach(switchElement => {
                switchElement.addEventListener('click', this.toggleProClass.bind(this));
            });

            document.querySelectorAll(".c-plans_switch-desktop").forEach(switchElement => {
                switchElement.addEventListener('click', this.toggleProClass.bind(this));
            });
        }
        
        this.isInView = new gsap.timeline({
            scrollTrigger: {
                invalidateOnRefresh: true,
                trigger: ".c-plans",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
                onEnter: () => this.playVideo(),
                onLeave: () => this.pauseVideo(),
                onEnterBack: () => this.playVideo(),
                onLeaveBack: () => this.pauseVideo(),
            }
        });
    }

    toggleProClass() {
        const currentTime = Date.now();
        if (currentTime - this.lastToggleTime >= 750) { // Check if 750ms have passed
            document.querySelector(".c-plans").classList.toggle("-pro");
            this.playPauseVideos();
            this.lastToggleTime = currentTime; // Update the last toggle time
        }
    }
    
    playPauseVideos() {
        // Get the video elements
        const videoPro = document.querySelector(".c-plans_video.-pro");
        const videoCore = document.querySelector(".c-plans_video.-core");
    
        if (document.querySelector(".c-plans").classList.contains('-pro')) {
            // Switching to pro video
            const currentTime = videoCore.currentTime; // Get current time from core video
            videoPro.currentTime = currentTime; // Set the same time on pro video
    
            setTimeout(()=>{
                videoCore.pause(); // Pause the pro video
            }, 400);
            videoPro.play(); // Play the pro video
        } else {
            // Switching to core video
            const currentTime = videoPro.currentTime; // Get current time from pro video
            videoCore.currentTime = currentTime; // Set the same time on core video
            
            setTimeout(()=>{
                videoPro.pause(); // Pause the pro video
            }, 400);
            videoCore.play(); // Play the core video
        }
    }

    // playPauseVideos() {
    //     const videoPro = document.querySelector(".c-plans_video.-pro");
    //     const videoCore = document.querySelector(".c-plans_video.-core");
    
    //     const switchToPro = document.querySelector(".c-plans").classList.contains('-pro');
    
    //     // Ensure both videos are ready
    //     const checkReadyState = (video) => new Promise(resolve => {
    //         if (video.readyState >= 3) { // HAVE_FUTURE_DATA or HAVE_ENOUGH_DATA
    //             resolve();
    //         } else {
    //             video.addEventListener('canplay', resolve, { once: true });
    //         }
    //     });
    
    //     Promise.all([checkReadyState(videoPro), checkReadyState(videoCore)]).then(() => {
    //         if (switchToPro) {
    //             const currentTime = videoCore.currentTime;
    //             videoPro.currentTime = currentTime;
    //             setTimeout(()=>{
    //                 videoCore.pause(); // Pause the pro video
    //             }, 400);
    //             videoPro.play();
    //         } else {
    //             const currentTime = videoPro.currentTime;
    //             videoCore.currentTime = currentTime;
    //             setTimeout(()=>{
    //                 videoPro.pause(); // Pause the pro video
    //             }, 400);
    //             videoCore.play();
    //         }
    //     });
    // }
    


    playVideo() {
        if (document.querySelector(".c-plans").classList.contains('-pro')) {
            document.querySelector(".c-plans_video.-pro").play();
        } else {
            document.querySelector(".c-plans_video.-core").play();
        }
    }

    pauseVideo() {
        if (document.querySelector(".c-plans").classList.contains('-pro')) {
            document.querySelector(".c-plans_video.-pro").pause();
        } else {
            document.querySelector(".c-plans_video.-core").pause();
        }
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    };

    destroy() {
        // Clean up the GSAP timeline and its ScrollTrigger
        if (this.isInView) {
            if (this.isInView.scrollTrigger) {
                this.isInView.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.isInView.kill();  // Kill the timeline
            this.isInView = null;  // Dereference the timeline for garbage collection
        }

        if (this.changeBg) {
            if (this.changeBg.scrollTrigger) {
                this.changeBg.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.changeBg.kill();  // Kill the timeline
            this.changeBg = null;  // Dereference the timeline for garbage collection
        }

        if (this.tl) {
            if (this.tl.scrollTrigger) {
                this.tl.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl.kill();  // Kill the timeline
            this.tl = null;  // Dereference the timeline for garbage collection
        }

         if (this.boxAnim) {
            if (this.boxAnim.scrollTrigger) {
                this.boxAnim.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.boxAnim.kill();  // Kill the timeline
            this.boxAnim = null;  // Dereference the timeline for garbage collection
        }
    
        // Remove event listeners for the switch elements
        document.querySelectorAll(".c-plans_switch").forEach(switchElement => {
            switchElement.removeEventListener('click', this.toggleProClass.bind(this));
        });

        // Remove event listeners for the switch elements
        document.querySelectorAll(".c-plans_switch-desktop").forEach(switchElement => {
            switchElement.removeEventListener('click', this.toggleProClass.bind(this));
        });

        document.querySelectorAll(".c-plans_headings").forEach(switchElement => {
            switchElement.removeEventListener('click', this.toggleProClass.bind(this));
        });

        document.querySelectorAll(".c-plans_videos").forEach(switchElement => {
            switchElement.removeEventListener('click', this.toggleProClass.bind(this));
        });
    
        // Additional cleanup if any other resources (e.g., other event listeners)
    }    
}
