import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

gsap.registerPlugin(ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        let media = gsap.matchMedia();      
        
        if (!this.isMobileDevice()) {
            this.isInView();
        }

        media.add("(min-width: 1000px)", () => {
            // Add the second animation sequence with to, chaining it after the first animation
            this.tl = new gsap.timeline({
                scrollTrigger: {
                    trigger: this.el,
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom bottom", // scene duration
                },
            });

            this.tl.fromTo(this.el.querySelector(".c-footer_boxes-core"), {
                yPercent: 100,
            }, {
                yPercent: 0,
                ease: "none",
            }, 0).fromTo(this.el.querySelector(".c-footer_gradients-core"), {
                yPercent: 150,
            }, {
                yPercent: 0,
                ease: "none",
            }, 0).fromTo(this.el.querySelector(".c-footer_boxes-pro"), {
                yPercent: 200,
            }, {
                yPercent: 0,
                ease: "none",
            }, 0).fromTo(this.el.querySelector(".c-footer_gradients-pro"), {
                yPercent: 200,
            }, {
                yPercent: 0,
                ease: "none",
            }, 0);
        })
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    }

    isInView() {
        this.inView = new gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                invalidateOnRefresh: true,
                start: "-=300px bottom",
                end: "bottom -=300px",
                scrub: true,
                onEnter: () => {
                    setTimeout(() => {
                        this.el.classList.add('is-inview');
                    }, 1000);
                },
                onLeaveBack: () => {
                    this.el.classList.remove('is-inview');

                    setTimeout(() => {
                        this.el.classList.remove('is-inview');
                    }, 1000);
                },                
            }
        });
    }

    destroy() {
        if (this.tl) {
            if (this.tl.scrollTrigger) {
                this.tl.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl.kill();  // Kill the timeline
            this.tl = null;  // Dereference the timeline for garbage collection
        }
    }    
}
