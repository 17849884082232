import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Lenis from 'lenis';
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.lenis = null;
        this.hash = null; // Variable to store the hash
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        this.lenis = new Lenis();

        const raf = (time) => {
            this.lenis.raf(time);
            requestAnimationFrame(raf);
        };

        requestAnimationFrame(raf);

        // this.lenis = new Lenis();

        // this.lenis.on('scroll', ScrollTrigger.update)

        // gsap.ticker.add((time)=>{
        //     this.lenis.raf(time * 1000)
        // })

        // gsap.ticker.lagSmoothing(0)

        this.lenis.stop();

        this.isLoader = document.querySelector(".c-loader");

        if (!this.isLoader) {
            this.lenis.start();
        }

        this.loader = document.querySelectorAll(".c-loader svg path");

        this.checkForHashAndScroll();

        if (this.loader.length === 0) {
            this.lenis.start();
            this.checkForHashAndScroll();
            this.attachAnchorClickListeners(); // Ensure listeners are attached initially
            return; // Exit if there are no loader elements
        }

        // Convert NodeList to Array for easy shuffling and manipulation
        const charsArray = Array.from(this.loader);
        gsap.utils.shuffle(charsArray);  // Shuffling the array for random order

        // Animating each character using forEach
        charsArray.forEach((char, index) => {
            gsap.to(char, {
                duration: 0.15,
                opacity: 0,
                ease: "none",
                delay: index * 0.0165 + 0.85,
                onStart: () => {
                    ScrollTrigger.refresh();
                },
                onComplete: () => {
                    if (index === charsArray.length - 1) {
                        if (this.isLoader) {
                            this.isLoader.classList.add("point-none");
                            document.documentElement.classList.add("is-first-loaded");
                            document.documentElement.classList.remove("is-first-loading");
                        }

                        if (this.isLoader) {
                            setTimeout(() => {
                                const loader = document.querySelector(".c-loader");
                                if (loader) loader.remove();
                            }, 1000);
                        }

                        // setTimeout(() => {
                        //     document.documentElement.classList.remove("is-first-loading");
                        // }, 3000);

                        this.lenis.start();
                        this.checkForHashAndScroll();
                        this.attachAnchorClickListeners(); // Ensure listeners are attached after initial load
                    }
                }
            });
        });

        window.addEventListener('beforeunload', () => {
            if (this.isLoader) {
                // Reset the clipPath or any other animation state here
                gsap.set(this.loader, { clearProps: 'all' });
            }
        });

        // Listen for hash changes
        window.addEventListener('hashchange', this.scrollToTopAndHash.bind(this));

        // Scroll to hash on initial load
        this.checkForHashAndScroll();

        // Safari detection and class addition
        if (this.isSafari() && !this.isMobileDevice()) {
            document.documentElement.classList.add('is-safari');
        }
    }

    attachAnchorClickListeners() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            if (!anchor.closest('.ch2')) {
                anchor.removeEventListener('click', this.handleAnchorClick); // Remove existing listener if any
                anchor.addEventListener('click', this.handleAnchorClick.bind(this));
            }
        });
    }

    handleAnchorClick(e) {
        e.preventDefault();
        const href = e.currentTarget.getAttribute('href');
        if (href) {
            // Store the hash in the variable
            this.hash = href;
            this.scrollToTopAndHash();
        }
    }

    checkForHashAndScroll() {
        if (document.readyState === 'complete') {
            this.scrollToTopAndHash();
        } else {
            window.addEventListener('load', this.scrollToTopAndHash.bind(this));
        }
    }

    scrollToTopAndHash() {
        setTimeout(() => {
            this.scrollToHash();
        }, 50); // Delay to ensure the top scroll completes
    }

    scrollToHash() {
        const hash = this.hash || window.location.hash; // Use the stored hash or fallback to window hash
        if (hash) {
            setTimeout(() => {
                const target = document.querySelector(hash);
                if (target) {
                    this.lenis.scrollTo(target, { offset: 0, duration: 1, onComplete: this.removeHashFromURL.bind(this) });
                }
            }, 50); // Short delay to ensure the top scroll completes
        }
    }

    removeHashFromURL() {
        history.replaceState(null, null, ' '); // This removes the hash from the URL without reloading the page
    }

    isSafari() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.includes('safari') && !ua.includes('chrome');
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    }

    destroy() {
        this.lenis.destroy();
    }
}
