import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.resizeAdded = false;  
        this.tickerAdded = false;  
        this.resizeTick = false;  
        this.marqueeSpeed = -1.75;  

        this.backgrounds = this.el.querySelectorAll('.c-rail_background');
        this.colors = this.el.querySelectorAll('.c-rail_color');

        this.throttledCheckResize = this.debounce(this.checkResize.bind(this), 200);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);

        let media = gsap.matchMedia();

        ScrollTrigger.config({ ignoreMobileResize: true });
        this.approach = this.getData("approach") != null;

        this.renderBind = this.render.bind(this);

        if (this.approach) {
            media.add("(min-width: 1000px)", () => {
                this.setup();
            });

            media.add("(max-width: 999px)", () => {
                this.teardown();
            });
        } else {
            this.setup();
        }
    }

    debounce(func, wait) {
        let timeout;
        return function(...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    checkResize() {
        if (this.viewportWidth !== window.innerWidth) {
            this.compute();
        }
    }

    setup() {
        this.compute();

        if (!this.resizeAdded) {
            window.addEventListener("resize", this.throttledCheckResize);
            this.resizeAdded = true;
        }

        if (this.approach) {
            this.isInViewApproach();
        } else {
            this.isInView();
        }

        if (!this.tickerAdded) {
            gsap.ticker.add(this.renderBind);
            this.tickerAdded = true;
        }
    }

    teardown() {
        if (this.tickerAdded) {
            gsap.ticker.remove(this.renderBind);
            this.tickerAdded = false;
        }

        if (this.inView && this.inView.scrollTrigger) {
            this.inView.scrollTrigger.kill();
            this.inView.kill();
            this.inView = null;
        }
    }

    isInView() {
        if (!this.inView) {
            this.inView = gsap.timeline({
                scrollTrigger: {
                    trigger: this.el,
                    invalidateOnRefresh: true,
                    start: "-=300px bottom", 
                    end: "bottom -=1000vw",
                    scrub: true,
                    onEnter: this.toggleInView.bind(this, true),
                    onLeave: this.toggleInView.bind(this, false),
                    onEnterBack: this.toggleInView.bind(this, true),
                    onLeaveBack: this.toggleInView.bind(this, false),
                }
            });
        }
    }

    isInViewApproach() {
        if (!this.inView) {
            this.inView = gsap.timeline({
                scrollTrigger: {
                    trigger: document.querySelector(".c-introduction"),
                    endTrigger: document.querySelector(".c-discover"),
                    invalidateOnRefresh: true,
                    start: "bottom bottom", 
                    end: "top center",
                    scrub: true,
                    onEnter: this.toggleInView.bind(this, true),
                    onLeave: this.toggleInView.bind(this, false),
                    onEnterBack: this.toggleInView.bind(this, true),
                    onLeaveBack: this.toggleInView.bind(this, false),
                }
            });
        }
    }

    toggleInView(inView) {
        this.el.classList.toggle('is-inview', inView);
        if (inView && !this.tickerAdded) {
            gsap.ticker.add(this.renderBind);
            this.tickerAdded = true;
        } else if (!inView && this.tickerAdded) {
            gsap.ticker.remove(this.renderBind);
            this.tickerAdded = false;
        }
    }

    compute() {
        this.viewportWidth = window.innerWidth;
        this.contentWidth = this.backgrounds[0].getBoundingClientRect().width / 2;
        this.contentWidthReverse = this.colors[0].getBoundingClientRect().width / 2;
        this.currentOffset = 0;

        this.backgroundsSet = gsap.quickSetter(this.backgrounds, "x", "px");
        this.colorsSet = gsap.quickSetter(this.colors, "x", "px");
    }

    render() {
        const delta = gsap.ticker.deltaRatio();
        this.currentOffset += this.marqueeSpeed * delta;

        if ((this.marqueeSpeed > 0 && this.currentOffset >= this.contentWidth) ||
            (this.marqueeSpeed < 0 && this.currentOffset <= -this.contentWidth)) {
            this.currentOffset -= Math.sign(this.marqueeSpeed) * this.contentWidth;
        }

        this.backgroundsSet(this.currentOffset);
        this.colorsSet(-this.currentOffset);
    }

    destroy() {
        this.teardown();

        if (this.resizeAdded) {
            window.removeEventListener("resize", this.throttledCheckResize);
            this.resizeAdded = false;
        }
    }    
}
