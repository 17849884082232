import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        gsap.registerPlugin(ScrollTrigger);
        
        let media = gsap.matchMedia();

        let initial = 0;
        
        // const container = this.el.querySelector('.c-work_stack');
        this.items = this.el.querySelectorAll('.c-work_stack .c-load');
        let currentIndex = 0; // Start with the first item
        let numberOfTargets = this.items.length;

        this.inView = new gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                invalidateOnRefresh: true,
                start: "-=300px bottom", // When the top of the marquee hits the bottom of the viewport
                end: "bottom -=300px",
                scrub: true,
                onEnter: () => {
                    this.el.classList.add('is-inview');
                },
                onLeave: () => {
                    this.el.classList.remove('is-inview');
                },
                onEnterBack: () => {
                    this.el.classList.add('is-inview');
                },
                onLeaveBack: () => {
                    this.el.classList.remove('is-inview');
                },
            }
        });

        media.add("(min-width: 1400px)", () => {
            // Add the second animation sequence with to, chaining it after the first animation
            this.tl = new gsap.timeline({
                scrollTrigger: {
                    trigger: ".c-work",
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom top", // scene duration
                },
                force3d: true,
            });

            this.tl.fromTo(".c-work .-left .-top", {
                y: "20vh",
            }, {
                y: "-25vh",
                ease: "none",
            }, 0).fromTo(".c-work .-left .-bottom", {
                y: "20vh",
            }, {
                y: "4vh",
                ease: "none",
            }, 0).fromTo(".c-work .-right p", {
                y: "-28vh",
            }, {
                y: "14vh",
                ease: "none",
            }, 0);
        });

        media.add("(min-width: 1000px) and (max-width: 1399px)", () => {
            this.tl = new gsap.timeline({
                scrollTrigger: {
                    trigger: ".c-work",
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: "top bottom",
                    end: "bottom top", // scene duration
                },
                force3d: true,
            });

            this.tl.fromTo(".c-work .-left .-top", {
                yPercent: 50,
            }, {
                yPercent: -75,
                ease: "none",
            }, 0).fromTo(".c-work .-left .-bottom", {
                yPercent: 700,
            }, {
                yPercent: 75,
                ease: "none",
            }, 0).fromTo(".c-work .-right p", {
                yPercent: -1000,
            }, {
                yPercent: 0,
                ease: "none",
            }, 0);
        });

        // Function to update the active class on the image this.items
        const updateActiveClass = () => {
            if (initial == 0) {

                initial = 1;

                // Remove classes from all this.items
                this.items.forEach(item => item.classList.remove('is-active'));
                this.items.forEach(item => item.classList.remove('is-prev'));
                this.items.forEach(item => item.classList.remove('is-exit'));

                this.items[currentIndex].classList.add('is-active');

                if(currentIndex !== 0) {
                    this.items[currentIndex - 1].classList.add('is-prev');
                }

                if(currentIndex !== 0 && currentIndex !== 1) {
                    this.items[currentIndex - 2].classList.add('is-exit');
                }

                if(currentIndex == 0) {
                    this.items[numberOfTargets - 1].classList.add('is-prev');
                    this.items[numberOfTargets - 2].classList.add('is-exit');
                }

                if(currentIndex == 1) {
                    this.items[numberOfTargets - 1].classList.add('is-exit');
                }

                currentIndex = (currentIndex + 1) % this.items.length;
            }

            if (this.el.classList.contains('is-inview') && initial == 1) {
                
                // Remove classes from all this.items
                this.items.forEach(item => item.classList.remove('is-active'));
                this.items.forEach(item => item.classList.remove('is-prev'));
                this.items.forEach(item => item.classList.remove('is-exit'));

                this.items[currentIndex].classList.add('is-active');

                if(currentIndex !== 0) {
                    this.items[currentIndex - 1].classList.add('is-prev');
                }

                if(currentIndex !== 0 && currentIndex !== 1) {
                    this.items[currentIndex - 2].classList.add('is-exit');
                }

                if(currentIndex == 0) {
                    this.items[numberOfTargets - 1].classList.add('is-prev');
                    this.items[numberOfTargets - 2].classList.add('is-exit');
                }

                if(currentIndex == 1) {
                    this.items[numberOfTargets - 1].classList.add('is-exit');
                }

                currentIndex = (currentIndex + 1) % this.items.length;
            }
        };

        // Initially call the function to set the first item active
        updateActiveClass();

        // Set an interval to cycle through the items every few seconds
        this.intervalId = setInterval(updateActiveClass, 1200); // Adjust time as needed     
    }

    isMobileDevice() {
        return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
    };

    destroy() {
        // Clean up intervals
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        // Clean up
        if (this.tl) {
            if (this.tl.scrollTrigger) {
                this.tl.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.tl.kill();  // Kill the timeline
            this.tl = null;  // Dereference the timeline for garbage collection
        }

        if (this.inView) {
            if (this.inView.scrollTrigger) {
                this.inView.scrollTrigger.kill();  // Safely kill the ScrollTrigger
            }
            this.inView.kill();  // Kill the timeline
            this.inView = null;  // Dereference the timeline for garbage collection
        }
    }
}
